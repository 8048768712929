
import { Vue, Component } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import LocationImg from '@/assets/img/location.png'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'

type Event = {
  lnglat: {
    lat: number;
    lng: number;
    getLng: () => number;
    getLat: () => number;
  };
}

@Component
export default class Location extends Vue {
  deviceId = ''
  loading = false
  longitude = 118.78
  latitude = 32.07
  marker: any = null
  siteName = ''
  created () {
    this.deviceId = this.$route.params.deviceId
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.devices.selectDeviceByDeviceId, {
      deviceId: this.deviceId
    }).then(res => {
      if (res.longitude && res.latitude) {
        this.longitude = parseFloat(res.longitude)
        this.latitude = parseFloat(res.latitude)
      }
      this.$nextTick(() => {
        this.loadMap(res.longitude, res.latitude)
      })
    }).finally(() => {
      this.loading = false
    })
  }

  loadMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then(() => {
      const map = new AMap.Map('container', {
        center: [this.longitude, this.latitude],
        zoom: 15
      })
      if (lng && lat) {
        this.addMarker(map, lng, lat)
      }
      this.loadLocationList(map)
      map.on('click', (e: Event) => {
        this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
        this.addMarker(map, this.longitude, this.latitude)
      })
      // 输入提示
      const autoOptions = {
        input: 'tipinput'
      }
      AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
        const auto = new AMap.Autocomplete(autoOptions)
        auto.on('select', (e: any) => {
          map.setZoomAndCenter(16, [e.poi.location.lng, e.poi.location.lat])
        })
      })
    }).catch(e => {
      this.$message({ type: 'error', message: '地图加载失败：' + JSON.stringify(e) })
    })
  }

  // 画电子围栏
  loadLocationList (map: AMap.Map) {
    this.$axios.get(this.$apis.project.projectLocationList, {
      objectId: this.$route.query.projectId
    }).then((res) => {
      // 画电子围栏
      drawPolygon(map, res.list, null, (e: Event) => {
        this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
        this.addMarker(map, this.longitude, this.latitude)
      })
    })
  }

  setLngLat (lng: number, lat: number) {
    this.longitude = lng
    this.latitude = lat
  }

  addMarker (map: AMap.Map, lng: number, lat: number) {
    if (this.marker) {
      map.remove(this.marker)
    }
    this.marker = new AMap.Marker({
      map: map,
      position: new AMap.LngLat(lng, lat),
      offset: new AMap.Pixel(-20, -40),
      icon: LocationImg,
      draggable: true
    })
    this.marker.on('dragend', (e: Event) => {
      this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
    })
    this.setLngLat(lng, lat)
    this.marker.on('dragstart', (e: Event) => {
      this.marker.setPosition(new AMap.LngLat(e.lnglat.lng, e.lnglat.lat))
    })
    map.add(this.marker)
  }

  submit () {
    this.loading = true
    this.$axios.post(this.$apis.devices.updateDeviceSelective, {
      deviceId: this.deviceId,
      longitude: this.longitude,
      latitude: this.latitude
    }).then(() => {
      this.$message({ message: '保存成功', type: 'success' })
    }).finally(() => {
      this.loading = false
    })
  }
}
